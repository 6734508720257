import React, { useState } from 'react';

import Button from '../button/Button';
import PropTypes from 'prop-types';


AboutToken.propTypes = {
    data: PropTypes.object.isRequired,

};
AboutToken.defaultProps = {
    data: {
        subheading: 'Token Information',
        heading: 'Here’s what you need to know about NFT',
        desc: 'Sunt in culpa qui officia dese runt mollit anim id est laborum velit esse cillum dolore eu fugiat nulla pariatu epteur sint occaecat',
        btn: true,
        btnTitle: "More About Us",
        link: '/about',
        isShape: false
    }
};

function AboutToken(props) {
    const { data } = props;
    return (
        <section className="token my-5 py-5">
            {data.isShape && <div className="shape"></div>}
            <div className="container section-mains">
                <div className="row">
                    <div className="col-xl-6 col-md-12 p-0 m-0">
                        <div data-aos="fade-left" data-aos-duration="2000" className='text-center'>
                            {data.img && (<img src={data.img} className="w-100 h-75" />)}
                            {data.imgHover && 
                            <div className="image-container">
                                {data.imgBase && (<img src={data.imgBase} className="base-image w-100" />)}
                                {data.imgHover && (<img src={data.imgHover} className="hover-image w-100" />)}
                            </div>}
                            {data.iframe && (<div style={{ position: "relative" }} className="iframe portfolio-box">
                                <iframe
                                    src={data.iframe}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style={{
                                        width: "100%",
                                    }}
                                    title={data.heading}
                                    className="embed-responsive-item"
                                ></iframe>
                            </div>
                            )}
                            {data.video && (
                                <div style={{ position: "relative" }} className="video portfolio-box">
                                    <iframe
                                        src={data.video}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                        style={{
                                            width: "100%",
                                        }}
                                        title={data.heading} className="embed-responsive-item"
                                    ></iframe>
                                </div>
                            )}
                            {data.videoPlay && (
                                <div style={{width: "100%", height: "100%", zIndex: -1 }}>
                                    <video autoPlay loop muted playsInline style={{ width: "100%", height: "100%", objectFit: data.objFitContain ? "contain" : "cover" }}>
                                        <source src={data.videoPlay} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12 my-auto">
                        <div className="block-text pd-0">
                            {data.subheading && (<h6 className="sub-heading"><span>{data.subheading}</span></h6>)}
                            {data.heading && (<h2 className="heading">{data.heading}</h2>)}
                            {data.desc && (<p className="mb-17" dangerouslySetInnerHTML={{ __html: data.desc }} />)}
                            {data.btn && (<Button title={data.btnTitle} link={data.link} target={data.target} />)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutToken;