import interactiveFlyersImg from "../industries/Hospitality/InteractiveFlyers.png";
import aiImg from "../industries/Hospitality/AI.jpg";
import holographicDisplaysImg from "../industries/Hospitality/Holographic Displays.jpg"
import smartStandsImg from "../industries/Hospitality/smart stands.jpg"
const vimeoVideoUrls = {
  tableMatte: "https://player.vimeo.com/video/938506208?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",    
};
const dataItem = [
  {
    id: 1,
    img: '',
    iframe: 'https://virtualdesignshub.com/viewer/sofitel',
    iframe2:"https://tours.avrlabs.io/Cigar-Lounge/Vtour/tour.html",
    title: "Interactive Virtual Tour",
    desc: `<p>Elevate hospitality with Interactive Virtual Tours, providing immersive previews of accommodations and venues, enhancing guest experiences and fostering anticipation before arrival.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn more",
    video: '',
    link: "/services/interactive-virtual-tours",
  },
  {
    id: 2,
    img: interactiveFlyersImg,
    iframe: '',
    title: "Interactive Flyers",
    backTransparent: true,
    desc: `<p> Innovate marketing with Interactive Flyers, combining traditional promotion with digital engagement. Provide guests dynamic content, exclusive offers, and interactive elements for an enhanced pre-arrival experience.<p>`,
    button: false,
    slug: "A02",
    btnTitle: "Learn more",
    video:'',
    link: "/services/extended-reality",
    scan:true
  },
  {
    id: 3,
    img: '',
    title: "Table-Matte",
    desc: `<p>Enrich dining experiences with Table-Matte technology, offering interactive menus, ordering options, and entertainment, creating a modern and engaging atmosphere within hospitality establishments.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['tableMatte'],
    link: "/services/extended-reality",
  },
  {
    id: 4,
    img: aiImg,
    title: "AI",
    desc: `<p>Enhance guest services in hospitality with AI. Implement artificial intelligence for personalized recommendations, efficient customer service, and streamlined operations, creating seamless and memorable stays.<p>`,
    button: true,
    slug: "S03",
    btnTitle: "Learn more",
    video: '',
    link: "/solutions/ai-virtual-beings",
  },
  {
    id: 5,
    img: holographicDisplaysImg,
    title: "Holographic Displays",
    desc: `<p>Captivate guests with Holographic Displays, offering visually stunning presentations, event information, and interactive content, creating a futuristic and engaging ambiance within hospitality venues.<p>`,
    button: true,
    slug: "S07",
    btnTitle: "Learn more",
    video: '',
    link: "/solutions/holographic-displays",
  },
  {
    id: 6,
    img: smartStandsImg,
    title: "Smart Stands",
    desc: `<p>Revolutionize event management in hospitality with Smart Stands. Integrate technology for interactive displays, seamless check-ins, and real-time information, enhancing efficiency and guest engagement at various functions.<p>`,
    button: true,
    slug: "S04",
    btnTitle: "Learn more",
    video: '',
    link: "/solutions/smart-stands",
  },
  
];

export default dataItem;
