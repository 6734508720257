
const dataPortfolio = [

    {
        id: 1,
        title: 'Advanced Sensor Technology',
        desc: `Our interactive Touchless games utilize state-of-the-art sensor technology to detect and respond to participants' movements in real-time, creating an immersive and responsive gaming experience.`
    },
    {
        id: 2,
        title: 'Dynamic Gaming Floors',
        desc: `With our dynamic gaming floors, participants can interact with the game environment using their feet, hands, or other body movements, adding an extra layer of excitement and challenge to the gameplay.`
    },
    {
        id: 3,
        title: 'High-Definition Screens',
        desc: 'Our high-definition screens display stunning graphics and animations, bringing the game to life in vivid detail and ensuring an engaging and immersive experience for participants.'
    },
]

export default dataPortfolio;