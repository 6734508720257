
import img1 from '../../assets/solutions/AI virtual being/multi images/Larger photo.jpg'
import img2 from '../../assets/solutions/AI virtual being/multi images/02.jpg'
import img3 from '../../assets/solutions/AI virtual being/multi images/03.jpg'


const dataBox = {
        desc2: ``,
        heading: 'Customized Avatar to your needs',
        subheading: 'Discover the Future of Customer Engagement!',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `Train it once and deploy it across various platforms. Your website, app, or on a range of screens and holographic displays at locations like your office, trade show, showroom, and more. `,
        dataImg:[
            {
                id: 1,
                img : img1,
                class: 'img1'
            },
            {
                id: 2,
                img : img2,
                class: 'img2'
            },
            {
                id: 3,
                img : img3,
                class: 'img5'
            },

        ]
    };

export default dataBox;