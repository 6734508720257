
const dataPortfolio = [

    {
        id: 1,
        title: 'Realistic Simulations',
        desc: 'Experience lifelike representations of products that help customers make informed choices.'
    },
    {
        id: 2,
        title: 'Augmented Reality Integration',
        desc: 'Visualize items in real-world settings through our Smart Stand, allowing for an accurate assessment of how products fit into your style.'
    },
    {
        id: 3,
        title: 'Customization Options',
        desc: 'Tailor your virtual try-on experience with adjustable colors, sizes, and styles, ensuring each customer finds what best suits them.'
    },
    {
        id: 4,
        title: 'Seamless Integration',
        desc: 'Effortlessly incorporate our Virtual Try-On solution with the Smart Stand into your website or mobile app, providing a smooth and cohesive shopping experience.'
    },
    // {
    //     id: 5,
    //     title: 'Customizable Options',
    //     desc: 'Tailor your virtual try-on experience with adjustable colors, sizes, and styles, ensuring each customer finds what best suits them.'
    // },
    {
        id: 5,
        title: 'User-Friendly Interface',
        desc: 'Enjoy an intuitive design that simplifies navigation and enhances the shopping experience.'
    },
]

export default dataPortfolio;