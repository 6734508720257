import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
// import AboutService from '../../components/about/AboutService';
import creativeAboutData from '../../assets/data/data-CCA-about';
import portfolioImg from '../../assets/Services/creative-concepts-and-activation/Innovative-Features.jpg'
import contactImg from '../../assets/Services/creative-concepts-and-activation/CTA2.png';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import dataCCAportfolio from '../../assets/data/data-CCA-portfolio';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import BannerCard from '../../components/banner/HomeBanner2';
import dataCard from '../../assets/data/event-data-card';

function CreativeConceptsActivation(props) {
    const holoVideo="https://virtualdesignshub.com/stagingnew/viewer/index.php?code=d1fe173d08e959397adf34b1d77e88d7";
    const roboticVideo="https://www.youtube.com/embed/vXLlnpXk0LI?controls=0&modestbranding=1&showinfo=0&rel=0";
    // const agrealityVideo="https://player.vimeo.com/video/937292378?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    // const transparentVideo="https://player.vimeo.com/video/937292397?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    // const curvedVideo="https://player.vimeo.com/video/937293737?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const vbVideo='https://player.vimeo.com/video/936100460?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0';
    const bannerData = {
        heading: 'Event Solutions by AVRLabs',
        subheading: "",
        desc: "Future-proof Your Next Event with Experiential Technology",
        button: false,
        btnTitle: "",
        link: '',
    };
    const token2Data = {
        heading: '',
        desc: `<ul class="main-theme-order">
        <li>Transform your exhibition experience with 360 booth solutions, where innovative interior design meets cutting-edge technology.</li>
        <li>Our expert team crafts unique, visually stunning booth interiors that reflect your brand identity and engage your audience.</li>
        <li>From concept to installation, we handle every detail, ensuring your booth stands out and operates flawlessly.</li></ul>`,
        btn: false,
        btnTitle: "View Demo",
        link: '',
        iframe: '',
        video: roboticVideo,
        img: ''
    }
    // const token2Data2 = {
    //     heading: 'Holobox Immersion',
    //     desc: `Holobox Immersion is more than just a display – it's a powerful tool for brands to create immersive and memorable experiences at events. With its ability to captivate audiences and deliver messages with impact, the Holobox is the future of communication in the events industry.`,
    //     btn: false,
    //     btnTitle: "",
    //     link: '',
    //     iframe: '',
    //     video: holoVideo,
    //     img: ''
    // }

    const token2DataNew = {
        heading: '',
        desc: `<ul class="main-theme-order">
        <li>Transform your exhibition experience with 360 booth solutions, where innovative interior design meets cutting-edge technology.</li>
        <li>Our expert team crafts unique, visually stunning booth interiors that reflect your brand identity and engage your audience.</li>
        <li>From concept to installation, we handle every detail, ensuring your booth stands out and operates flawlessly.</li></ul>`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: holoVideo,
        video: "",
        img: ''
    }
    
    // const token2Data3 = {
    //     heading: 'Augmented Reality Engagement',
    //     desc: `<ul><li>- Transform your events into interactive experiences with augmented reality. Engage your audience on a whole new level, driving deeper connections and lasting memories.</li>
    //     <li>- Interactive Product Demos</li>
    //     <li>- Immersive Experiences</li>
    //     <li>- Virtual Avatars</li>
    //     <li>- Brand  Awareness</li>
    //     <li>- QR Code Integration</li></ul>`,
    //     btn: false,
    //     btnTitle: "View Demo",
    //     link: '',
    //     iframe: '',
    //     video: agrealityVideo,
    //     img: ''
    // }
    // const token2Data4 = {
    //     heading: 'Curved Screen Spectacle',
    //     desc: `Transform your presentations with our state-of-the-art curved screen technology. Engage your audience in a visually captivating experience that enhances your message and leaves a memorable impact.`,
    //     btn: false,
    //     btnTitle: "",
    //     link: '',
    //     iframe: '',
    //     video: curvedVideo,
    //     img: ''
    // }
    // const token2Data5 = {
    //     heading: 'Transparent Screens',
    //     desc: `Transparency meets innovation with our screens. Showcase your content in crystal-clear quality while maintaining transparency, creating a seamless blend of digital and physical space.`,
    //     btn: false,
    //     btnTitle: "",
    //     link: '',
    //     iframe: '',
    //     video: transparentVideo,
    //     img: ''
    // }
    const token2Data6 = {
        heading: 'High-Tech Event Booth',
        desc: `<p>We carefully design event booths tailored to your brand with the latest technology to give your brand an unforgettable experience. Here's why they stand out:<p>
        <ul>
            <li>-  Audio-Visual and Technology Integration</li>
            <li>- Video Walls & Displays</li>
            <li>- Interactive Kiosks with AI Assistant</li>
            <li>- Soundscapes and Ambiance</li>
            <li>- Product Demonstrations with Augmented Reality (AR) Interactions</li>
            <li>- Virtual Reality (VR) Experiences</li>
            <li>- Experiential Zones</li>
            <li>- Transparent Screens of Any Shapes</li>
            <li>- Interactive Sensor Game Floor</li>
        </ul>
        <p>With our high-tech event stands, you can elevate your brand presence, engage your audience, and create unforgettable experiences that drive success at your events.<p>`,
        btn: false,
        btnTitle: "View Demo",
        link: '',
        iframe: 'https://portal.arviewz.com/products/346/modelviewer?arModel=true',
        video: '',
        img: ''
    }
    const token2Data7 = {
        heading: 'AI Virtual Being',
        desc: `Harness the power of artificial intelligence to create lifelike virtual beings that engage and interact with your audience. Whether it's customer service avatars or brand ambassadors, our AI virtual beings drive engagement and enhance the attendee experience.`,
        btn: false,
        btnTitle: "",
        link: '',
        iframe: '',
        video: vbVideo,
        img: ''
    }

    const contactData = {
        heading: '',
        desc: 'Make your booth the highlight of any event. Schedule a consultation with our experts today!',
        btn: true,
        btnTitle: 'Request a Demo',
        link: 'https://calendly.com/raed-avr/',
        img: contactImg
    }
    const portfolioHeaderData = {
        img: portfolioImg,
        heading: 'Why Choose AVR Labs?',
    };
    const cardHeaderData ={
        subheading: '',
        heading: '',
        desc: 'Smart High-Tech Booth – Integrate the latest technology seamlessly, providing visitors with a one-of-a-kind experience.'
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            {/* <AboutService data={creativeAboutData} /> */}
            <div className='main-sec-empty-space-btm'>
                <ServiceToken2 data={creativeAboutData} />
            </div>

            <BannerCard data={dataCard} dataBlock={cardHeaderData} />

            <section>
                <div className="block-text center">
                    <h2 className="text-center"><span>360 Solutions for Exhibitions Stands</span></h2>
                    <h2 className="text-center"><span>From Design to execution</span></h2>
                </div>
            </section>

            <div className='main-sec-empty-space-btm'>
                <ServiceToken2 data={token2Data} />
            </div>

            <Create style={{ marginTop: "100px" }} data={contactData} />


            <ServiceToken data={token2DataNew} />

            {/* <div className='main-sec-empty-space-all'>
                <ServiceToken data={token2Data2} />
            </div>

            <ServiceToken2 data={token2Data3} />

            <div className='main-sec-empty-space-all'>
                <ServiceToken data={token2Data4} />
            </div>

            <ServiceToken2 data={token2Data5} /> */}
            <div className='main-sec-empty-space-all'>
                <ServiceToken2 data={token2Data6} />
            </div>
            {/* <ServiceToken2 data={token2Data7} /> */}
            <SolutionPortfolio headerData={portfolioHeaderData} data={dataCCAportfolio} step='false' numbering='true' />
            
            <Footer />
        </div>
    );
}

export default CreativeConceptsActivation;