import React, { useState } from 'react';



function Speciality(props) {

    const { data, headerData , icon } = props;

    return (
        <section className="speciality">
            {/* <div className="shape right"></div> */}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            {headerData.subheading && <h6 className="sub-heading"><span>{headerData.subheading}</span></h6>}
                            {headerData.heading && <h2 className="heading pd">{headerData.heading}</h2>}
                            {headerData.desc && <p className="">{headerData.desc}</p>}
                        </div>
                    </div>
                    {
                        data.map(idx => (
                            <div key={idx.id} className={headerData.cls ? headerData.cls : "col-xl-4 col-md-6"}>
                                <div className="speciality-box" >
                                    {idx.img && <div className="icon">
                                        <img src={idx.img} alt="AVRLabs" style={{ width: '60px', height: '60px' }} />
                                    </div>}
                                    <h5 className="title">{idx.title}</h5>
                                    <p>{idx.desc}</p>
                                    <h3 className="number">0{idx.id}</h3>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </section>

    );
}

export default Speciality;