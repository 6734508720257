import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService from '../../components/about/AboutService';
import gameAboutData from '../../assets/data/data-interactive-game-about';
import portfolioImg from '../../assets/Services/interactive-games/Revolutionizing-Gaming.png';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import dataGamePortfolio from '../../assets/data/data-game-portfolio';
import dataChooseUsPortfolio from '../../assets/data/data-game-choose-us-portfolio';
import specialityDataBox from '../../assets/data/data-game-speciality';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import chooseUsPortfolioImg from '../../assets/Services/interactive-games/Why-Choose-Us.png';
import contactImg from '../../assets/Services/interactive-games/CTA.png';
import Touchless from '../../assets/Services/interactive-games/Touchless games video.mp4';
import gameGif from '../../assets/Services/interactive-games/Snapchat lens gif.gif';
import snapApp from '../../assets/Services/interactive-games/Snapchat logo.png';
import gameQR from '../../assets/Services/interactive-games/gameQR.png';
import ServiceToken2 from '../../components/token/ServiceToken2';

function InteractiveGames(props) {
    const SpecialityData = {
        heading: 'Why Interactive Touchless Games?',
        cls: "col-xl-4 col-md-6"
    };
    const chooseUsHeader = {
        heading: "Why Choose Us?",
        desc: `<p>With over <b>15 years</b> of experience and certified experts in immersive technology, AVR Labs is a trusted leader in the field.</p>
                <p>Our team brings a wealth of knowledge and expertise to every project, ensuring that your event is a success from concept to execution. </p>
                <p>Your trusted partner with industry professionals who are committed to deliver exceptional results.</p>`,
        img: chooseUsPortfolioImg
    };
    const bannerData = {
        heading: 'Interactive Apps/Games',
        subheading: "",
        desc: "Engage Your Audience, Drive Results",
        button: false,
        btnTitle: "",
        link: '',
        headerVideo: Touchless
    };
    const portfolioHeaderData = {
        heading: 'Revolutionizing Gaming',
        subheading: 'Sensory Symphony',
        img: portfolioImg
    };
    const contactData = {
        heading: 'Partner with Us',
        desc: "Let's create an unforgettable experience for your audience together.",
        btn: true,
        btnTitle: 'Contact Us',
        link: '/contact',
        img: contactImg
    }
    const token2Data3 = {
        heading: 'AR kids entertainment for interactive games page',
        desc: `<h5 class="mb-1">Transform Play with AR-Powered Experiences for Kids</h5>
        <p class="mb-1">Take kids on an interactive adventure where their favorite characters leap off the screen and into vibrant 3D worlds. With AR technology, storytelling becomes hands-on, bringing learning and entertainment to life in exciting new ways.</p>
        <h5 class="mb-1">Key Features:</h5>
        <ul><li>- 3D Character Magic: Classic 2D characters transform into lively 3D animations.</li>
        <li>- Color & Animate: Kids’ coloring brings characters to life in real-time 3D.</li>
        <li>- Interactive Learning: Storytelling and exploration in immersive AR environments.</li>
        </ul>`,
        btn: false,
        btnTitle: "View Demo",
        link: '',
        iframe: '',
        video: "https://player.vimeo.com/video/937292378?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
        img: ''
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <AboutService data={gameAboutData} />
            <SolutionPortfolio headerData={portfolioHeaderData} data={dataGamePortfolio} step='false' />

            <section className='py-5'>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xl-12 col-md-12">
                            <div className="project-box mb-0">
                                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                                    <iframe
                                        src="https://www.youtube.com/embed/eQ_qxr5a4yw?controls=0&modestbranding=1&showinfo=0&rel=0"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write;"
                                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                        title="AVRLabs"
                                        className="embed-responsive-item"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SolutionSpeciality data={specialityDataBox} headerData={SpecialityData} />

            <section className='py-5'>
                <div className="container">
                    <div className="row text-center align-items-center">
                        <div className="col-xl-5 col-md-5 col-sm-5 col-12 mb-4 d-flex justify-content-center postion-relative">
                            <img src={gameGif} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 col-12 mb-4 d-flex justify-content-center postion-relative">
                            <div>
                                <img src={snapApp} alt="AVRlabs Game" className="mxw-100" />
                                <h6 className='mt-3'>Try this Snapchat Lens</h6>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 col-sm-3 col-12 d-flex justify-content-center">
                            <img src={gameQR} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                    </div>

                </div>
            </section>

            <ServiceToken2 data={token2Data3} />
            <div className="mb-5 main-sec-no-padd">
                <SolutionPortfolio headerData={chooseUsHeader} data={dataChooseUsPortfolio} step='false' numbering='true' />
            </div>
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default InteractiveGames;