
import img1 from '../../assets/solutions/Holograpic displays/Holofan.jpg'

const dataBox = {
        heading: 'Holofan',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `An advanced holographic display system that creates captivating visuals, appearing to float in mid-air. Equipped with high-quality RGB LEDs, it produces vibrant colors and lifelike images. `,
        img : img1,
};

export default dataBox;