
import img1 from '../solutions/AI virtual being/icons/01.png';
import img2 from '../solutions/AI virtual being/icons/02.png';
import img3 from '../solutions/AI virtual being/icons/03.png';
import img4 from '../solutions/AI virtual being/icons/04.png';



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: '24/7 Availability',
        desc: 'Ensure customers receive support anytime, leading to higher engagement and retention rates.'
    },
    {
        id: 2,
        img: img2,
        title: 'Increased Customer Satisfaction',
        desc: '85% of users report improved satisfaction with AI-driven support.'
    },
    {
        id: 3,
        img: img3,
        title: 'Enhanced Efficiency',
        desc: 'Businesses see a 30% reduction in response times.'
    },
    {
        id: 4,
        img: img4,
        title: 'Cost Savings',
        desc: 'Companies save up to 50% on customer service costs by implementing AI Virtual Beings.'
    },


]

export default dataPortfolio;