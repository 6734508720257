import virtualTryonImg from "../industries/Retail Stores/virtual try on.jpg";
import holographicDisplayImg from "../industries/Retail Stores/hologram display.jpg";
import virtualWebsiteImg from "../industries/Retail Stores/Virtual website.jpg";
import interactiveVirtualTourimg from "../industries/Retail Stores/interactive virtual tour.jpg";
import transparentScreenimg from "../industries/Retail Stores/transparant screen (on).jpg";

const vimeoVideoUrls = {
  AI: "https://player.vimeo.com/video/936099814?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
  packaging: "https://player.vimeo.com/video/936132157?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",   
};
const dataItem = [
  {
    id: 1,
    img: virtualTryonImg,
    title: "Virtual Try-on",
    desc: `<p> Virtual Try-On experiences, revolutionizing retail. Customers can virtually explore and try on products in real-time using AR technology, enhancing the online and offline shopping journey with interactive, engaging and personalized experience. This will increase your conversions and decrease returns.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: "",
    link: "",
  },
  {
    id: 2,
    img: '',
    title: "AI",
    desc: `<p>Explore lifelike virtual beings, conversational beings that act as your representative on your shop window, App, Website or any Kiosk. They will engage with your visitors and customer to guide them and give them personlized recomendations to increase your conversions and brand loyality. For 24/7 and with a smile.<p>`,
    button: true,
    slug: "S03",
    btnTitle: "Learn More",
    video: vimeoVideoUrls['AI'],
    link: "/solutions/ai-virtual-beings",
  },
  {
    id: 3,
    img: transparentScreenimg,
    title: "Transparent Screen",
    desc: `<p>Transparent screens seamlessly blend digital content with the physical world, enhancing retail displays, interactive exhibits, and digital signage. turning boring Glass partitions to  an engaging and dynamic visual experience for diverse applications.<p>`,
    button: true,
    slug: "S05",
    btnTitle: "Learn More",
    video: "",
    link: "/solutions/S05",
  },
  {
    id: 4,
    img: holographicDisplayImg,
    title: "Holographic Displays",
    desc: `<p>A holographic displays provide a captivating and immersive visual experience. It is one of the best ways to attract visitors visitors attention and make you stand out from the crowd. Combining this with motion sensors creates a mind-bending and unforgetable experience to your visitors.<p>`,
    button: true,
    slug: "S07",
    btnTitle: "Learn More",
    video: "",
    link: "/solutions/holographic-displays",
  },
  {
    id: 5,
    img: '',
    iframe:'https://portal.arviewz.com/products/351/modelviewer',
    title: "Interactive Activation",
    desc: `<p>Interactive activation refers to a process or system that responds dynamically to user input, engagement, or stimuli. a solution that can combine multiple technologies and creative ideas to grab visitors attention to a product or service.<p>`,
    button: true,
    slug: "S06",
    btnTitle: "Learn More",
    video: '',
    link: "/solutions/S06",
  },
  {
    id: 6,
    img: '',
    iframe:'https://portal.arviewz.com/products/620/modelviewer?arModel=true',
    title: "AR for Products and Promotions",
    desc: `<p>let your customers experience your product in their own environment virtually. They can view your product and interact with it in an immersive way using Mixed Reality glasses or just by using their phone or tablet camra. click on View in your space to Experince the product in your own enviroment<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: '',
    link: "",
  },
  {
    id: 7,
    img: virtualWebsiteImg,
    iframe:'',
    title: "Virtual Website",
    desc: `<p>A virtual website refers to an online 3D website that can be viewed like any other website. Wheather by using a desktop, mobile or VR device. 2D website will be from the past soon.<p>`,
    button: true,
    slug: "A05",
    btnTitle: "Learn More",
    video: '',
    link: "/services/virtual-web-development",
  },
  {
    id: 8,
    img: '',
    iframe:'https://portal.arviewz.com/products/346/modelviewer',
    title: "Smart Stands",
    desc: `<p>Smart stands integrate advanced technologies to enhance various environments. In retail, they can feature interactive displays and digital signage. In exhibitions, they may incorporate Holograms for engaging presentations, creating dynamic and interactive spaces.<p>`,
    button: true,
    slug: "S04",
    btnTitle: "Learn More",
    video: '',
    link: "/solutions/smart-stands",
  },
  {
    id: 9,
    img: interactiveVirtualTourimg,
    iframe:'',
    title: "Interactive Virtual Tour",
    desc: `<p>An interactive virtual tour combines digital technology with real-world exploration. Users navigate through immersive environments, interact with elements, and experience a dynamic, user-driven journey, often used for showcasing spaces or attractions remotely.<p>`,
    button: true,
    slug: "A08",
    btnTitle: "Learn More",
    video: '',
    link: "/services/interactive-virtual-tours",
  },
  {
    id: 10,
    img: '',
    iframe:'',
    title: "Packaging AR",
    desc: `<p>Turn boring printout outs to an immersive interactive experience. Turn any image, business card, brochure or any package to an unforgettable experience.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: vimeoVideoUrls['packaging'],
    link: "",
  },
];

export default dataItem;
