import React from 'react';
import Banner from '../../components/banner/SolutionBanner';
import Footer from '../../components/footer/Footer';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import dataHolodisplay from '../../assets/data/data-holo-display-speciality';
import Create from '../../components/create/Create2';
import AboutSolution from '../../components/about/AboutSolution';
import sectionOne from '../../assets/data/data-display-section-one';
import sectionThree from '../../assets/data/data-display-section-three';
import sectionTwo from '../../assets/data/data-display-section-two';
import sectionFour from '../../assets/data/data-display-section-four';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import heroImg from "../../assets/solutions/Holograpic displays/Hero.jpg";
import ctaImg from "../../assets/solutions/Holograpic displays/CTA.png";
import headerVideo from "../../assets/solutions/Holograpic displays/Power of True.mp4";
import transparantScreen from "../../assets/solutions/Holograpic displays/transparant-screens1.jpg";
import transparantScreen2 from "../../assets/solutions/Holograpic displays/transparant-screens2.jpg";
import SeamlessIntegration from "../../assets/solutions/Holograpic displays/Seamless-Integration.jpg";
import VibrantClarity from "../../assets/solutions/Holograpic displays/Vibrant-Clarity.jpg";
import InteractiveExperience from "../../assets/solutions/Holograpic displays/Interactive-Experience.jpg";

function Holo(props) {
     const bannerData = {
        heading: 'Holographic Displays',
        subheading:"Experience the Future of Display Technology with AVR Labs",
        desc:"Introducing a new era of visual communication with our groundbreaking Holographic Displays. Captivate your audience with lifelike 3D visuals that seem to leap off the screen, creating an immersive experience like no other. Our displays are revolutionizing the way brands connect with their audience, offering unparalleled engagement and impact.",
        // images:[heroImg]
        video: headerVideo
    };
    const SpecialityData = {
        heading: 'Revolutionizing Brand Engagement',
    };
    const contactData = {
        heading: 'Transform Your Brand Today with Holographic <br> Displays',
        btn: true,
        btnTitle: 'Schedule a Call with Our Experts',
        link: '/contact',
        img:ctaImg
    }
    const sectionFive = {
        heading: 'Take your presentations to the next level with Transparent Holographic Screens',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `These amazing displays let you showcase high-quality holographic visuals right over real-world objects, creating a stunning see-through effect that truly captures attention.`,
        imgBase: transparantScreen,
        imgHover: transparantScreen2,
    };
    const sectionSix = {
        heading: 'Seamless Integration',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `Place digital content over physical products for a dynamic, dual-layer experience.`,
        img : SeamlessIntegration,
    };
    const sectionSeven = {
        heading: 'Vibrant Clarity',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `High-definition, transparent visuals bring stories to life.`,
        img : VibrantClarity,
    };
    const sectionEight = {
        heading: 'Interactive Experience',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `Multi-touch capabilities create hands-on interaction, deepening audience engagement.`,
        img : InteractiveExperience,
    };
    return (
        <div className='home-2 wrapper'>
                <Banner data={bannerData} />
                <AboutSolution data={sectionOne} />
                <SolutionSpeciality data={dataHolodisplay} headerData={SpecialityData}/>
                <ServiceToken2 data={sectionTwo} />
                <ServiceToken data={sectionThree} />
                <ServiceToken2 data={sectionFour} />
                <Create style={{ marginTop: "100px" }} data={contactData} />

                <section className="main-top-transparent-sec">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-12">
                                <div className="block-text center">
                                    <h1 className="heading">Transparent OLED Screens: A New Dimension in Visual Display</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ServiceToken2 data={sectionFive} />
                    <ServiceToken data={sectionSix} />
                    <ServiceToken2 data={sectionSeven} />
                    <ServiceToken data={sectionEight} />
                </section>

                <Footer />
        </div>
    );
}

export default Holo;