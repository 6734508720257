import vrforEntertainmentrainingimg from "../industries/Events/VR for entertainment & training.jpg";
import interactiveActivationImg from "../industries/Events/interactive activation.jpg";

const vimeoVideoUrls = {
    aiKisok: "https://player.vimeo.com/video/936132224?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",    
};
const dataItem = [
  {
    id: 1,
    img: '',
    iframe: '',
    title: "AI Kiosk",
    desc: `<p>Elevate event engagement with AI Kiosks, offering interactive information, registrations, and personalized experiences. Enhance attendee interactions through smart and responsive kiosk solutions.<p>`,
    button: true,
    slug: "A06",
    btnTitle: "Learn more",
    video: vimeoVideoUrls['aiKisok'],
    link: "/services/event-tech-and-booth-stands",
  },
  {
    id: 2,
    img: '',
    iframe: 'https://virtualdesignshub.com/viewer/index.php?code=a8f15eda80c50adb0e71943adc8015cf',
    title: "VR for Entertainment & Training",
    desc: `<p>Revolutionize events with VR for Entertainment & Training. Provide immersive experiences, from virtual concerts to training simulations, creating dynamic and memorable engagements for diverse event audiences.<p>`,
    button: true,
    slug: "A02",
    btnTitle: "Learn more",
    video:'' ,
    link: "/services/extended-reality",
  },
  {
    id: 3,
    img: interactiveActivationImg,
    title: "Interactive Activation",
    desc: `<p>Transform events with Interactive Activation. Engage attendees through dynamic, real-time responses to their input, fostering personalized and participatory experiences that amplify the overall impact of the event.<p>`,
    button: true,
    slug: "A06",
    btnTitle: "Learn more",
    video: '',
    link: "/services/event-tech-and-booth-stands",
  },
  // {
  //   id: 4,
  //   img: '',
  //   iframe: 'https://virtualdesignshub.com/viewer/index.php?code=a8f15eda80c50adb0e71943adc8015cf',
  //   title: "Creative Concepts",
  //   desc: `<p>Enrich events with Creative Concepts. From immersive installations to dynamic exhibits, these innovative ideas captivate audiences, conveying messages and enhancing the overall experience through creative and interactive designs.<p>`,
  //   button: true,
  //   slug: "A06",
  //   btnTitle: "Learn more",
  //   video: '',
  //   link: "/services/event-tech-and-booth-stands",
  // },
  
];

export default dataItem;
