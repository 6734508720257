import React, { useState } from 'react';

import Button from '../button/Button';
import img from '../../assets/images/layouts/about-06.png'
import PropTypes from 'prop-types';

AboutService2.propTypes = {
    data: PropTypes.object.isRequired,

};
AboutService2.defaultProps = {
    data: {
        subheading: 'About us',
        heading: 'Hight Quality NFT Collections',
        desc: 'AVRlabs is the premier marketplace for nifties, which are digital items you can truly own for yourself',
        desc1: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occae cat cupidatat non proident, sunt in culpa qui officia dese runt mollit anim id est laborum velit esse cillum dolore eu fugiat nulla pariatu epteur sint occaecat',
        btn: true,
        btnTitle: "More About Us",
        link: '/about',
        img: img
    }
};

function AboutService2(props) {
    const { data } = props;
    return (
        <section className="about">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center pd-0">
                            {data.subheading && <h6 className="sub-heading"><span>{data.subheading}</span></h6>}
                            {data.heading && <h2 className="heading pd">{data.heading}</h2>}
                        </div>
                        <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                            {data.img && <img src={data.img} alt="AVRLabs" className='mxw-100' />}
                            {(data.desc && !data.roadmap) && <p className="mb-0">{data.desc}</p>}
                            {(data.desc2 && !data.roadmap) && <p className="mb-0">{data.desc2}</p>}
                            {(data.desc3 && !data.roadmap) && <p className="mb-0">{data.desc3}</p>}
                            {data.btn && <Button title={data.btnTitle} link={data.link} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutService2;