import React from 'react';
import Banner from '../../components/banner/SolutionBanner';
import Footer from '../../components/footer/Footer';
import heroImg from "../../assets/solutions/smart standerds/hero.jpg";
import faqImg from '../../assets/solutions/smart standerds/Unleashing-Creative-Concepts.jpg';
import dataVirtualTryOnPortfolio from '../../assets/data/data-virtual-try-on-portfolio';
import portfolioImg from '../../assets/solutions/smart standerds/virtual-try-on.jpg';
import Stand2 from '../../assets/solutions/smart standerds/Stand2.jpg';
import Stand1 from '../../assets/solutions/smart standerds/why-choose-avr-labs.jpg';
import SolutionPortfolio from '../../components/portfolio/SolutionPortfolio';
import SolutionFaqs from '../../components/faqs/SolutionFaqs';
import smartStanderdsFaqs from '../../assets/data/data-smart-standerd';
import Project from '../../components/project/ProjectService';
import dataProject from '../../assets/data/data-smart-standerd-applications';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import dataTryOn from '../../assets/data/data-virtual-try-on-speciality';
import Contact from '../../components/contact/Contact';

function VirtualBeings(props) {
    const bannerData = {
        heading: 'Smart Stands',
        desc: "Introducing the Interactive Smart Stand, the pinnacle of innovation in event technology.",
        images: [heroImg]
    };
    const headerData = {
        img: portfolioImg,
        heading: 'Enhance Your Shopping Experience with Virtual Try-On',
        desc: 'Virtual Try-On technology, integrated with our Smart Stand, offers a modern and engaging way for customers to shop online. This innovative combination allows shoppers to try on clothing, accessories, and beauty products from the comfort of their homes, making the shopping journey more interactive and user-friendly.',
    };
    const faqHeader = {
        img: faqImg,
        heading: 'Innovative Features <br /> Unmatched Impact',
    }
    const SpecialityData = {
        heading: 'Core Applications',
    };
    const projectHeader = {
        heading: 'Industry Application',
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <div className='pt-5'>
                <SolutionFaqs data={smartStanderdsFaqs} dataBlock={faqHeader} />
            </div>
            <Project data={dataProject} headerData={projectHeader} />
            <SolutionPortfolio headerData={headerData} data={dataVirtualTryOnPortfolio} step="false" />
            <SolutionSpeciality data={dataTryOn} headerData={SpecialityData} />
            <section className='py-1'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-12 col-sm-12 col-12 mb-4">
                            <img src={Stand2} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                        <div className="col-xl-4 col-md-12 col-sm-12 col-12 mb-4">
                            <div class="content">
                                <h4 class="title">
                                    Enhance customer engagement with our Interactive Smart Stand
                                </h4>
                                <p>
                                    Delivering responsive touchscreens, multimedia integration, and tailored design for impactful experiences across retail, events, and brand activations.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-12 col-sm-12 col-12">
                            <img src={Stand1} alt="AVRlabs Game" className="mxw-100" />
                        </div>
                    </div>

                </div>
            </section>
            <Contact subheading='Get In touch' heading="Ready to Elevate Your Events?" infoheading="Let’s Talk!" />
            <Footer />
        </div>
    );
}

export default VirtualBeings;