
import img1 from '../../assets/Services/creative-concepts-and-activation/Unleashing-Creative-Concepts.jpg'
const dataBox = {
        heading: 'Unleashing Creative Concepts',
        subheading: 'Shine Bright',
        button: true,
        link:'/contact',
        btnTitle:'Schedule Your Consultation Call Today',
        desc: `We bring the future of event technology to life with cutting-edge solutions that turn your event booth into an immersive experience that visitors won’t forget. We believe in creating standout moments that drive engagement, excite your audience, and elevate your brand.`,
        video: "https://www.youtube.com/embed/cqpFzxH-ILw?controls=0&modestbranding=1&showinfo=0&rel=0"
        // dataImg: [
        //     {
        //         id: 1,
        //         img : img1,
        //         class: 'img1'
        //     }
        // ]
    };

export default dataBox;