 import muralsImg from "../industries/Art/MuralsInteractive.jpg";
import aiImg from "../industries/Art/AI to sell art.jpg";
import interactiveVirtualTourImg from "../industries/Art/interactiveVirtualTour.jpeg";
import nftForArt from "../industries/Art/nft gallary.jpg";
import aiForArtImg from "../industries/Art/AR-for-art.jpg";
const vimeoVideoUrls = {
  arArtGallary: "https://player.vimeo.com/video/936096123?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0",
};
const dataItem = [
  {
    id: 1,
    img: aiForArtImg,
    title: "AR for Art",
    desc: `<p>Dont just show them your Art. Let them experience it. With just a click they will view it in their own environment, Click on View it in your space, to experience Nagla Ali’s piece on your wall.<p>`,
    button: true,
    slug: "S01",
    btnTitle: "Learn More",
    video: "",
    link: "/solutions/ar-visualizer",
  },
  {
    id: 2,
    img: muralsImg,
    backTransparent: true,
    title: "Murals (Interactive Art )",
    desc: `<p>Bring your paintings to life with Augmented Reality Murals! Blending the classic with modern to bend reality. 
    Let your art speak its own story.  No complications or apps to be downloaded. it as simple as just scanning a QR and pointing it to the art.
    Scan QR and point at the image. You need another screen or a printout.  Contact us<p>`,
    button: false,
    slug: "",
    scan:true
  },
  {
    id: 3,
    iframe:"https://virtualdesignshub.com/stagingnew/viewer/index.php?code=9a1158154dfa42caddbd0694a4e9bdc8",
    title: "Virtual Art Gallery",
    desc: `<p>Virtual Art Gallery, Make your own exhibition virtually. Invite people to meet them and explain all about it. Whether using a VR device or just your phone. We will help you make it, design it and manage it.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    video: "",
    link: ""
  },
  {
    id: 4,
    title: "AR Art Gallery",
    desc: `<p>We can help you create an exhibition of your art pieces in multiple locations at the same time with Augmented Reality Geo Location.<p>`,
    button: false,
    video: vimeoVideoUrls['arArtGallary'],
  },
  {
    id: 5,
    img: interactiveVirtualTourImg,
    title: "Interactive Virtual Tour",
    desc: `<p>Interactive Virtual Art Tours, You know how important the virtual tour for your gallery but you cant afford doing it every time an item gets replaced! You want your customers to be able to tour around your gallery at anytime and from anywhere to see the latest of your collection and get briefed about it?</p>
            <p>Well! we got you covered. We train you and give you all the tools you need to do it yourself and stay on top of your game.<p>`,
    button: true,
    slug: "",
    btnTitle: "Learn more",
    link: "/services/interactive-virtual-tours",
  },
  {
    id: 6,
    img: nftForArt,
    title: "NFTs for art pieces",
    desc: `<p>AVR Labs embraces NFTs (Non-Fungible Tokens) for art pieces, revolutionizing ownership and authenticity. Elevate your art collection with blockchain technology, ensuring unique, verifiable digital assets and fostering a new era in the art market.<p>`,
    button: false,
    slug: "",
    btnTitle: "",
    link: "",
  },
  {
    id: 7,
    img: aiImg,
    title: "AI",
    desc: `<p>Explore lifelike virtual beings, conversational beings that act as your representative on your websites or exhibitions.  They will show  related art of yours to your customers preferences and tell the story behind it.</p>
         <p><strong> Caution: </strong> Combining this AI experience with AR (Augmented Reality) will result in an extreme immersive experience.  You can Learn more here<p>`,
    button: true,
    slug: "S01",
    btnTitle: "Learn More",
    video: "",
    link: "/solutions/ai-virtual-beings",
  },
];

export default dataItem;
