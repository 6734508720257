
import img1 from '../../assets/solutions/Holograpic displays/about1.jpg';
import videoPlayP from '../../assets/solutions/Holograpic displays/Power of True.mp4'


const dataBox = {
        heading: 'Power of True 3D Vision',
        subheading: 'About Us',
        button: false,
        link:'',
        btnTitle:'Learn More',
        desc: `With AVR Labs' Holographic Displays, you can showcase your products and presentations in stunning 3D, allowing your audience to interact with them in ways they never thought possible. Our displays create a sense of depth and dimensionality that's simply mesmerizing, ensuring your brand stands out from the competition.`,
        dataImg:[
            {
                id: 1,
                img : "",
                videoPlay: videoPlayP,
                objFitContain: true,
                class: 'img1'
            },
            
        ]
    };

export default dataBox;