import React , {useState} from 'react';
import PropTypes from 'prop-types';

import { Navigation , Pagination   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import DynamicModal from '../DynamicModal';

Project.propTypes = {
    data: PropTypes.array,
    blockTop: PropTypes.object
};

function Project(props) {
    const {data, blockTop} = props;
    // Lazy initialization
    const [dataBlock] = useState(() => blockTop || {
        subheading: '',
        heading: 'Virtual Solutions',
        btn: false,
        btnTitle: "Contact us",
        link: '/contact',
    });
    const [modalConfig, setModalConfig] = useState({
        show: false,
        title: "",
        bodyContent: "",
        footerButtons: [],
        size: "xl",
    });
    const handleShowModal = (item) => {
        setModalConfig({
          show: true,
          title: item.title,
          bodyContent: (
            <div>
              <p>{item.desc}</p>
            </div>
          ),
          iframe: item.iframe,
          video: item.video,
        //   img: item.img,
          classNameAll: "extendedRealityCls",
          size: "xl",
        });
    };
    return (
        <section className="project">
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12 main-top-project">
                        <div className="block-text center">
                            {dataBlock.subheading && <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>}
                            <h3 className="heading" >{dataBlock.heading}</h3>
                        </div>

                        <Swiper
                                className="project-swiper"
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                loop={true}
                                modules={[ Navigation , Pagination ]}
                                navigation
                                pagination={{
                                    clickable: true,
                                }}
                                
                            >

                                {
                                    data.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <div className="swiper-slide">
                                                <div className={`project-box ${idx.classCustom ? idx.classCustom : ''}`} onClick={() => handleShowModal(idx)}>
                                                    <div className="image">
                                                        {idx.isLink && <Link to="/nfts">
                                                            <img src={idx.img} alt="AVRLabs" />
                                                        </Link>}
                                                        {(!idx.isLink && idx.img) &&
                                                            <img src={idx.img} alt="AVRLabs" className='main-img-project' />
                                                        }
                                                        {idx.iframe && (<div style={{ position: "relative" }} className="iframe portfolio-box">
                                                            <iframe
                                                                src={idx.iframe}
                                                                frameBorder="0"
                                                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                title={idx.heading}
                                                                className="embed-responsive-item"
                                                            ></iframe>
                                                        </div>
                                                        )}
                                                        {idx.video && (
                                                            <div style={{ position: "relative" }} className="video portfolio-box">
                                                                <iframe
                                                                    src={idx.video}
                                                                    frameBorder="0"
                                                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    title={idx.heading} className="embed-responsive-item"
                                                                ></iframe>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="content">
                                                        {idx.isLink && <Link to="/nfts" className="h5 title">{idx.title}</Link>}
                                                        {!idx.isLink && <h5 className="h5 title">{idx.title}</h5>}
                                                    </div>
                                                    {/* {idx.desc && <div className='description-cls'>
                                                        {idx.desc}
                                                    </div>} */}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                               
                            </Swiper>

                        {dataBlock.btn &&
                            <div className='text-center mt-4'>
                                <Button title={dataBlock.btnTitle} link={dataBlock.link} />
                            </div>
                        }
                    </div>

                </div>
            </div>
            <DynamicModal
                show={modalConfig.show}
                onHide={() => setModalConfig((prev) => ({ ...prev, show: false }))}
                title={modalConfig.title}
                bodyContent={modalConfig.bodyContent}
                iframe={modalConfig.iframe}
                video={modalConfig.video}
                img={modalConfig.img}
                classNameAll = "extendedRealityCls"
                size= "xl"
            />
        </section>
    );
}

export default Project;