import Smart from "../home/slider/event and boot/Smart High Tech Booth.png";
import Virtual from "../home/slider/event and boot/AI Virtual Being.png";
import Wayfinding from "../home/slider/event and boot/AR powered Wayfinding.png";
import Interactive from "../home/slider/event and boot/Interactive Motion Games.png";
import Hologram from "../home/slider/event and boot/Hologram Displays.jpg";
import Tangible from "../home/slider/event and boot/Tangible Tables.png";
import Curved from "../home/slider/event and boot/Curved & Transparent Screens.png";
import Robot from "../home/slider/event and boot/Robot Arm Displays.png";
import Visualization from "../home/slider/event and boot/3d Product Visualization.png";
import Augmented from "../home/slider/event and boot/Augmented Reality Enhancements.png";
import Store from "../home/slider/event and boot/Virtual Store.png";



const dataCard = [
  {
    id: 1,
    img: Smart,
    title: "Smart High-Tech Booth",
    desc: "Integrate the latest technology seamlessly, providing visitors with a one-of-a-kind experience."
  },
  {
    id: 2,
    img: Virtual,
    title: "AI Virtual Being",
    desc: "Engage your audience with lifelike AI avatars.",
    link: '/solutions/ai-virtual-beings'
  },
  {
    id: 3,
    img: Wayfinding,
    title: "AR-Powered Wayfinding",
    desc: "Help visitors navigate to your booth easily."
    //link: '/services/extended-reality'
  },
  {
    id: 4,
    img: Interactive,
    title: "Interactive Motion Games",
    desc: "Gamify visitor interaction with touchless motion sensors.",
    link:'/services/interactive-games',
  },
  {
    id: 5,
    img: Hologram,
    title: "Hologram Displays",
    desc: "Create stunning, immersive 3D visuals.",
    link:'/solutions/holographic-displays',
  },
  {
    id: 6,
    img: Tangible,
    title: "Tangible Tables",
    desc: "Provide hands-on, interactive demos of your products."
    //link:'/solutions/S05',
  },
  {
    id: 7,
    img: Curved,
    title: "Curved & Transparent Screens",
    desc: "Showcase dynamic visuals that stand out."
    //link:'/solutions/holographic-displays',
  },
  {
    id: 8,
    img: Robot,
    title: "Robot Arm Displays",
    desc: "Add motion and intrigue with dynamic, robotic displays."
    //link:'/solutions/S08',
  },
  {
    id: 9,
    img: Visualization,
    title: "3D Product Visualization",
    desc: "Bring your products to life with interactive 3D models."
    //link:'/solutions/S08',
  },
  {
    id: 10,
    img: Augmented,
    title: "Augmented Reality Enhancements",
    desc: "Elevate product displays with AR integration."
    //link:'/solutions/S08',
  },
  {
    id: 11,
    img: Store,
    title: "Virtual Store",
    desc: "Extend your reach with a 3D virtual storefront.",
    link:'/industries/retail-stores',
  },
];

export default dataCard;
