const dataTechConsultancy = [

    {
        id: 'One',
        title: 'Expert Tech Consultancy',
        desc: `Gain valuable insights and strategic advice to navigate today’s technology landscape with confidence and make decisions that propel your business forward.`
    },
    {
        id: 'Two',
        title: 'Diverse Tech Expertise',
        desc: `Our team specializes in AR, VR, AI, Blockchain, and the Metaverse, providing cutting-edge solutions that align with your goals.`
    },
    {
        id: 'Three',
        title: 'Tailored Strategies for Success',
        desc: `We start with a detailed analysis of your business to develop a customized tech strategy that meets your unique objectives.`
    },
    {
        id: "Four",
        title: '15+ Years of Experience',
        desc: `Trust in our proven track record and industry knowledge to guide your business with expertise that sets us apart.`
    },
]

export default dataTechConsultancy;