import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';

import AIAssistance from '../../assets/Services/experiential-technology-interior/AI-Assistance.jpeg';
import TransparentScreens from '../../assets/Services/experiential-technology-interior/TransparentScreens.jpeg';
import PersonalizedAI from '../../assets/Services/experiential-technology-interior/PersonalizedAI.jpeg';
import Augmentedreality from '../../assets/Services/experiential-technology-interior/Augmentedreality.jpeg';
import Supportteam from '../../assets/Services/experiential-technology-interior/Supportteam.jpeg';
import TangibleTables from '../../assets/Services/experiential-technology-interior/TangibleTables.jpeg';

import contactImg from '../../assets/Services/have-a-project2.png';

function ExperientialTechnology(props) {
    const bannerData = {
        heading: 'Experiential Technology & Smart Interiors',
        desc: "We combine smart technology with modern design to create spaces that are engaging, comfortable, and interactive. Upgrade your space with our innovative experiential technology and smart furniture.",
        button: false,
        btnTitle: "Talk To Our XR Experts",
        link: '/contact',
        images:[],
    };
    const token2DataFirst = {
        heading: 'AI Assistance',
        desc: `<p class="mb-4">AI-powered avatars handle guest inquiries, manage reservations, and provide personalized recommendations.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: AIAssistance,
        isShape: false
    }

    const token2DataSecond = {
        heading: 'Transparent Screens',
        desc: `<p class="mb-2">Provide maps, event schedules, and local attractions while keeping the view clear. Guests can easily access important information at any time.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: TransparentScreens,
        isShape: false
    }

    const token2DataThree = {
        heading: 'Personalized AI shopper at your service',
        desc: `<p class="mb-4">Analyze customer preferences with AI assistance to suggest products that fit their needs.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: PersonalizedAI,
        isShape: false
    }

    const token2DataFour = {
        heading: 'Augmented reality setups',
        desc: `<p class="mb-2">Allow customers to see products in various settings for a hands-on experience.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: Augmentedreality,
        isShape: false
    }

    const token2DataFive = {
        heading: 'Augmented reality setups',
        desc: `<p class="mb-2">Allow customers to see products in various settings for a hands-on experience.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: Augmentedreality,
        isShape: false
    }

    const token2DataSix = {
        heading: '',
        desc: `<p class="mb-2">Support team collaboration with interactive presentations and data sharing that work for both in-person and remote meetings with transparent screens.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: Supportteam,
        isShape: false
    }

    const token2DataSeven = {
        heading: 'Tangible Tables',
        desc: `<p class="mb-2">Support team collaboration with interactive presentations and data sharing that work for both in-person and remote meetings with transparent screens.</p>`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: TangibleTables,
        isShape: false
    }

    const contactData = {
        heading: 'Have a project?',
        desc: 'Book your FREE Consultation now to transform your business before it’s too late!',
        btn: true,
        btnTitle: 'Contact us',
        link: '/contact',
        img: contactImg
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <section className="my-5 p-5">
                <h2 className="text-center">
                    Enhance Guest Experiences
                </h2>
            </section>
            <ServiceToken data={token2DataFirst} />
            <ServiceToken2 data={token2DataSecond} />
            <section className="my-5 p-5">
                <h2 className="text-center">
                    Create an Engaging Retail Experience
                </h2>
            </section>
            <ServiceToken data={token2DataThree} />
            <ServiceToken2 data={token2DataFour} />

            <section className="my-5 p-5">
                <h2 className="text-center">
                    Interactive Meeting Spaces
                </h2>
            </section>
            <ServiceToken data={token2DataSix} />
            <ServiceToken2 data={token2DataSeven} />

            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default ExperientialTechnology;