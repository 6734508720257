import img1 from "../Services/interactive-games/features-icons/Engage Your Audience.svg";
import img2 from "../Services/interactive-games/features-icons/Create Memorable Experiences.svg";
import img3 from "../Services/interactive-games/features-icons/Drive Interaction.svg";
const dataBox = [
  {
    id: 1,
    img: img1,
    title: "Engage Your Audience",
    desc: "Capture the attention of your audience and keep them engaged with interactive touchless games that are both fun and challenging.",
  },
  {
    id: 2,
    img: img2,
    title: "Create Memorable Experiences",
    desc: "Stand out from the crowd and create memorable experiences that leave a lasting impression on your audience.",
  },
  {
    id: 3,
    img: img3,
    title: "Drive Interaction",
    desc: "Encourage interaction and participation among your attendees, fostering connections and creating opportunities for networking and engagement.",
  },
];

export default dataBox;
