import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService from '../../components/about/AboutService';
import tourAboutData from '../../assets/data/data-interactive-virtual-tour-about';
import dataBox from '../../assets/data/data-interactive-virtual-tour-speciality';
import SolutionSpeciality from '../../components/speciality/SolutionSpeciality';
import Project from '../../components/project/ProjectService';
import dataProject from '../../assets/data/data-interactive-virtual-tours-project';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import dataIVTortfolio from '../../assets/data/data-IVT-portfolio';
import contactImg from '../../assets/Services/have-a-project2.png';
import Roadmap from '../../components/roadmap/Roadmap2';
import trustedImg from '../../assets/Services/interactive-virtual-tours/trusted.png';
import headerVideo from '../../assets/Services/interactive-virtual-tours/features-icons/header video.mp4';
import dataChooseUsPortfolio from '../../assets/data/data-IVT-choose-us-portfolio';
import chooseUsHeader from '../../assets/data/data-IVT-choose-us-header';
import ProjectInfo from '../../components/project/ProjectInfo';
import Button from '../../components/button/Button';
import zyedComplex from "../../assets/Services/virtual-web-development/Zayed Complex.jpg";

function InteractiveVirtualTours(props) {
    const SpecialityData = {
        subheading: 'Our Speciality',
        heading: 'Features of Interactive Virtual Tours',
        desc: `Our immersive 360º tours transport your audience into your space, whether it's an educational institute, showroom, newly designed house or under constructed building.`,
        cls: "col-xl-4 col-md-6"
    };
    const bannerData = {
        heading: 'Interactive Virtual Tours',
        subheading: "",
        desc: "",
        button: false,
        btnTitle: "",
        link: '',
        headerVideo: headerVideo
    };
    const tokenData = {
        heading: 'Trusted by Google Street View',
        desc: `Seamlessly integrate dynamic virtual tours into Google Maps, ensuring smooth navigation and authentic connections with your 
        audience. Utilize our expertise as certified Google Street View experts to expand your digital reach, engage viewers with immersive 
        experiences, and empower confident decisions for your business.`,
        btn: false,
        link: '',
        iframe: '',
        video: '',
        img: trustedImg,
    }
    const tokenDataJT = {
        heading: 'Bring Your Facility to Life with an immersive Virtual Tour!',
        desc: ``,
        btn: false,
        link: '',
        iframe: '',
        video: 'https://www.youtube.com/embed/MW8hrXWlw5A?controls=0&modestbranding=1&showinfo=0&rel=0',
        img: "",
    }

    const tokenDataBK = {
        heading: 'Showcase your 3D designs in a virtual tour that leaves a lasting impact on your clients.',
        desc: ``,
        btn: false,
        link: '',
        iframe: '',
        video: 'https://www.youtube.com/embed/wbXuY0qDfHA?controls=0&modestbranding=1&showinfo=0&rel=0',
        img: "",
    }
    const contactData = {
        heading: 'Book your FREE Consultation',
        desc: '',
        btn: true,
        btnTitle: 'Call Now',
        link: '/contact',
        img: contactImg
    }
    const projectHeader = {
        heading: 'Our Range of Interactive Virtual Tours:',
        subheading: 'Our Virtual Tours'
    }
    const portfolioHeaderData = {
        heading: 'Why do you need a Virtual Tour?',
    };
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <AboutService data={tourAboutData} />
            <ServiceToken2 data={tokenDataJT}/>
            <div className='main-sec-empty-space-all'>
                <ServiceToken data={tokenData}/>
            </div>
            <section className="project s2 my-0 mt-0">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xl-10 offset-xl-1 col-md-12">
                        <h3 className="heading pd">Explore Zayed Complex in Dubai</h3>
                            <div className="project-box">
                                <div style={{ position: 'relative' }}>
                                   <img src={zyedComplex} alt="AVRLabs" className='w-100 h-100'/>
                                </div>
                            </div>
                            <p className="my-2 mb-2">Our immersive 360º tours transport your audience into your space, whether it's an educational institute, showroom, newly designed house or under constructed building.</p>
                            <Button link="/contact" title="Contact Us" />
                            <a className="action-btn mx-2" href="/project/P18"><span>View Features</span></a>
                        </div>
                    </div>
                </div>
            </section>
            <SolutionSpeciality data={dataBox} headerData={SpecialityData} />
            <ServiceToken data={tokenDataBK}/>
            <Project data={dataProject} headerData={projectHeader} />
            <Roadmap headerData={portfolioHeaderData} data={dataIVTortfolio} />
            <ProjectInfo heading={chooseUsHeader.heading} subheading={chooseUsHeader.desc} data={dataChooseUsPortfolio} />
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default InteractiveVirtualTours;