import img1 from "../Services/technology-consultancy/icons/specializedworkshopsicon.png";
import img2 from "../Services/technology-consultancy/icons/ComprehensiveTraining.png";
import img3 from "../Services/technology-consultancy/icons/WideRangeOfTopics.png";
import img4 from "../Services/technology-consultancy/icons/HandsOnLearning.png";
import img5 from "../Services/technology-consultancy/icons/EmpowerYourTeam.png";
import img6 from "../Services/technology-consultancy/icons/EmpowerYourTeam.png";

const dataBoxTechCon = [
  {
    id: 1,
    img: img1,
    title: "Specialized Workshops",
    desc: "Workshops designed to introduce you to the latest technologies in an engaging way.",
  },
  {
    id: 2,
    img: img2,
    title: "Comprehensive Training Sessions",
    desc: "Gain practical knowledge from our experienced team to confidently apply new tech skills in your projects.",
  },
  {
    id: 3,
    img: img3,
    title: "Wide Range of Topics",
    desc: "Explore a range of subjects, from design tools to advanced techniques, to enhance your skills and understanding.",
  },
  {
    id: 4,
    img: img4,
    title: "Hands-On Learning",
    desc: "Our interactive approach ensures you gain practical experience, making it easier to apply new skills directly to your work.",
  },
  {
    id: 5,
    img: img5,
    title: "Empower Your Team",
    desc: "Equip your team with cutting-edge knowledge and techniques that drive innovation and efficiency.",
  },
  {
    id: 6,
    img: img6,
    title: "Stay Competitive",
    desc: "Our training programs will equip you to effectively use technology, ensuring your business stays ahead of the competition.",
  },
];

export default dataBoxTechCon;
