
import videoPlayP from '../../assets/solutions/Holograpic displays/Pyramid Stand.mp4'

const dataBox = {
        heading: 'Pyramid Stand',
        button: true,
        link:'',
        btnTitle:'More About Us',
        desc: `The Pyramid Stand offers a unique mixed-reality display experience by combining physical objects with holographic video elements. `,
        desc2:'Experience an immersive viewing angle for diverse applications.',
        img : "",
        videoPlay: videoPlayP
        // video: "https://player.vimeo.com/video/1032911536?h=edd86f0613&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    };

export default dataBox;