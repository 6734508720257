

const info = [
        // {
        //   title: "Seamless Web Integration",
        //   desc: "With just a few lines of code, add Ai Chatbot to your website. Make sure each customer is being served in a more personalized way.",
        // },
        // {
        //   title: "Virtual Beings In XR And Metaverse",
        //   desc: `<p>Elevate your immersive experience with Ai Chatbot on VR and AR devices. Our technology allows seamless integration of virtual beings into your app or game.</p><p>Discover A World Where AI Technology Meets Human-Like Engagement Contact us to get your personalized Ai Virtual Being (contact form)</p>`,
        //   class: "right",
        // },
        // {
        //   title: "Versatile Deployment",
        //   desc: `<p>Seamlessly integrates with various platforms and touchpoints including websites, apps, and VR devices.</p>`,
        // },
        // {
        //   title: "24/7 Availability",
        //   desc: `<p>Ensures continuous access to your clients, even when you or your employees are unavailable.</p>`,
        //   class: "right",
        // }, 
        {
          title: "Showrooms",
          desc: "Transform your showroom into an interactive space with AI-powered assistance. Provide instant product details, answers, and personalized recommendations, empowering faster decisions, boosting purchases, and fostering brand loyalty.",
        },
        {
          title: "Reception and concierge",
          desc: `<p>Enhance guest experiences with AI Virtual Beings at your reception. They manage check-ins, answer inquiries, and assist with bookings, ensuring seamless service that keeps guests satisfied and engaged.</p>`,
          class: "right",
        },
        {
          title: "Healthcare",
          desc: `<p>Provide instant, accurate information to patients, improve appointment scheduling, and offer support 24/7. AI Virtual Beings can reduce administrative workload and enhance patient satisfaction.</p>`,
        },
        {
          title: "Retail",
          desc: `<p>Enhance online shopping experiences with AI brand representative that provides personalized recommendations and instant customer support, increasing conversion rates and customer loyalty.</p>`,
          class: "right",
        }, 
        {
          title: "Hospitality",
          desc: `<p>Simplify booking and provide personalized travel suggestions with AI assistance. This improves operational efficiency while offering exceptional guest satisfaction, all while delivering 24/7 support.</p>`,
        },        
      ];

export default info;