import React from 'react';
import Banner from '../../components/banner/ServiceBanner';
import Footer from '../../components/footer/Footer';
import Create from '../../components/create/Create2';
import AboutService from '../../components/about/AboutService';
import AboutService2 from '../../components/about/AboutService2';
import ServiceToken from '../../components/token/ServiceToken';
import ServiceToken2 from '../../components/token/ServiceToken2';
import extendedRealityAbout from '../../assets/data/data-extended-reality-about';
import about1Img from '../../assets/Services/extended-reality/Unlock.png';
import about2Img from '../../assets/Services/extended-reality/Step.png';
import token2DataImg from '../../assets/Services/extended-reality/AR Marker-Based Solutions.jpg';
import token5DataImg from '../../assets/Services/extended-reality/VR Training.jpg';
import token9DataImg from '../../assets/Services/extended-reality/Virtual Meeting.png';
import token10DataImg from '../../assets/Services/extended-reality/VR Training Modules.jpg';
import token11DataImg from '../../assets/Services/extended-reality/explore-with-extended-reality.jpg';
import token12DataImg from '../../assets/Services/extended-reality/manufactoring.jpg';
import token13DataImg from '../../assets/Services/extended-reality/architecture.jpg';
import token14DataImg from '../../assets/Services/extended-reality/healthcare training.jpg';
import token15DataImg from '../../assets/Services/extended-reality/education.jpg';
import contactImg from '../../assets/Services/have-a-project2.png';
import SolutionCounter from '../../components/counter/SolutionCounter';

function ExtendedReality(props) {
    const tokenData4Video = "https://player.vimeo.com/video/936132185?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const vlabVideo = "https://player.vimeo.com/video/937239399?badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0";
    const tokenData7Video = "https://www.youtube.com/embed/VMkAcatTqSI?controls=0&modestbranding=1&showinfo=0&rel=0";
    const vrTrainingModule ="https://www.youtube.com/embed/11hllx3UM18?controls=0&modestbranding=1&showinfo=0&rel=0";
    const CounterStats = [
        {
            count: '3x ',
            title: "Increase customer engagement"
        },
        {
            count: "30% ",
            title: 'Improve learning retention'
        },
        {
            count: '25%',
            title: "Reduce operational costs"
        },
        {
            count:'40%',
            title:"Increase brand recall"
        },
        {
            count:'50%',
            title:"Improve remote collaboration efficiency"
        }
    ];
    const CounterStats2 = [
        {
            count: '20%',
            title: "Increase conversion rates"
        },
        {
            count: "30% ",
            title: 'Reduce product development cycles'
        },
        {
            count: '40%',
            title: "Reduce medical errors"
        },
        {
            count:'50%',
            title:"Increase property inquiries"
        },
        {
            count:'60%',
            title:"Increase student engagement"
        }
    ];
    const bannerData = {
        heading: 'Extended Reality (AR, VR, MR, XR)',
        desc: "Unlock the Infinite Possibilities of Extended Reality (XR) with AVR Labs",
        button: true,
        btnTitle: "Talk To Our XR Experts",
        link: '/contact',
        images:[],
    };
    const aboutservice2Data2 = {
        heading: 'Step into Immersive Mixed Reality and Virtual Reality (VR) Experiences',
        subheading: "",
        desc: "Whether showcasing products, providing training simulations, or creating virtual environments for education, our VR services deliver unparalleled levels of engagement and interactivity.",
        btn: false,
        btnTitle: "More About Us",
        link: '/about',
        img: about2Img
    };
    const aboutservice2Data1 = {
        heading: 'Unlock the Potential of Augmented Reality (AR)',
        subheading: "",
        desc: "We push the boundaries of reality with our cutting-edge Augmented Reality solutions. Elevate your customer experience and enhance brand recognition with our innovative AR experiences that seamlessly blend with the real world.",
        btn: false,
        btnTitle: "More About Us",
        link: '/about',
        img: about1Img
    };
    const token2Data = {
        // subheading: 'Token Information',
        heading: 'Product Configuration',
        desc: `Personalize every aspect of your products with our intuitive interface, offering customization options for colors, materials, sizes, and features.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: 'https://cw.avrlabs.io/texture/',
        video: '',
        img: '',
        isShape: false
    }
    const token2Data2 = {
        // subheading: 'Token Information',
        heading: 'AR Marker-Based Solutions',
        desc: `Create custom markers to trigger AR experiences, providing additional information about your products or services.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token2DataImg,
        isShape: false
    }
    const token2Data3 = {
        // subheading: 'Token Information',
        heading: 'AR Marketing',
        desc: `Transform dining experiences with interactive table mats, brochures, and QR stands, offering menu viewing, food ordering, and engaging games for customers.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: 'https://portal.arviewz.com/products/620/modelviewer?arModel=true',
        video: '',
        img: '',
        isShape: false
    }
    const token2Data4 = {
        // subheading: 'Token Information',
        heading: 'Model Geo Location AR',
        desc: `Visualize your products in real-world locations, allowing customers to interact with them like never before.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: tokenData4Video,
        img: '',
        isShape: false
    }
    const token2Data5 = {
        // subheading: 'Token Information',
        heading: 'XR Reality Simulators',
        desc: `Practice real-world skills in a safe environment with custom XR reality simulators, improving proficiency and reducing training costs.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token5DataImg,
        isShape: false
    }
    const token2Data6 = {
        // subheading: 'Token Information',
        heading: 'Web-Based Experience',
        desc: `Access immersive VR experiences via web browsers, enabling seamless engagement with your products or services from anywhere in the world.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: 'https://cw.avrlabs.io/Airplane/index.html',
        video: '',
        img: '',
        isShape: false
    }
    const token2Data7 = {
        // subheading: 'Token Information',
        heading: 'Virtual Environment Design',
        desc: `Create custom virtual environments for training simulations, product demonstrations, and educational purposes tailored to your specific needs.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: tokenData7Video,
        img: '',
        isShape: false
    }
    const token2Data8 = {
        // subheading: 'Token Information',
        heading: 'Virtual Labs for Schools and Universities',
        desc: `Enhance learning experiences with custom virtual labs, allowing students to conduct experiments and learn in fully immersive environments.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: vlabVideo,
        img: '',
        isShape: false
    }
    const token2Data9 = {
        // subheading: 'Token Information',
        heading: 'Virtual Meeting Platforms',
        desc: `Connect with customers or colleagues worldwide with our virtual meeting feature, enabling seamless communication and collaboration.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token9DataImg
    }
    const token2Data10 = {
        // subheading: 'Token Information',
        heading: 'VR Training Modules',
        desc: `Guide users through complex processes with interactive VR training modules, enhancing learning outcomes and skill development.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: vrTrainingModule,
        img: ""
    }
    const token2Data11 = {
        // subheading: 'Token Information',
        heading: 'Retail and E-Commerce',
        desc: `Transform the shopping experience with AR try-on features, virtual showrooms, and interactive product demonstrations that drive conversions and reduce returns.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token11DataImg
    }
    const token2Data12 = {
        // subheading: 'Token Information',
        heading: 'Manufacturing and Engineering',
        desc: `Streamline product design and prototyping with VR visualization tools, remote collaboration, and interactive 3D modeling that accelerate time-to-market.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token12DataImg
    }
    const token2Data14 = {
        // subheading: 'Token Information',
        heading: 'Real Estate and Architecture',
        desc: `Showcase properties with immersive 360° virtual tours, augmented reality floor plans, and interactive visualization tools that streamline the sales process and enhance customer engagement.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token13DataImg
    }
    const token2Data13 = {
        // subheading: 'Token Information',
        heading: 'Healthcare and Medical Training',
        desc: `Enhance medical training and patient care with immersive VR simulations, virtual surgeries, and diagnostic tools that improve clinical outcomes and reduce risks.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token14DataImg
    }
    const token2Data15 = {
        // subheading: 'Token Information',
        heading: 'Education and Training',
        desc: `Create immersive learning experiences with VR field trips, interactive simulations, and gamified educational content that engage students and improve knowledge retention.`,
        btn: false,
        btnTitle: "But Token",
        link: '',
        iframe: '',
        video: '',
        img: token15DataImg
    }
    const contactData = {
        heading: 'Have a project?',
        desc: 'Book your FREE Consultation now to transform your business before it’s too late!',
        btn: true,
        btnTitle: 'Contact us',
        link: '/contact',
        img: contactImg
    }
    return (
        <div className='home-2 wrapper'>
            <Banner data={bannerData} />
            <section className="about about-first mt-0 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center pd-0"></div>
                            <div className="about__main center aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
                                <p className="mb-0">
                                    Step into a world where reality meets innovation at AVR Labs. With over <b>15 years</b> of experience and 50+ satsified clients across the globe, AVR Labs combines expertise with innovation to delover tailored XR solutions that meet your unique needs. Whether you're a business owner, educator, or marketer, Get ready to transform your business with an immersive experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="my-5 py-5 mt-0">
                <AboutService data={extendedRealityAbout} />
            </div>
            <AboutService2 data={aboutservice2Data2} />
            <ServiceToken2 data={token2Data10} />
            <ServiceToken data={token2Data5} />
            
            <ServiceToken2 data={token2Data6} />
            <ServiceToken data={token2Data7} />
            <ServiceToken2 data={token2Data8} />
            <ServiceToken data={token2Data9} />
            <SolutionCounter data={CounterStats} icon="false" showimg="false"  />
            <section className="my-5 py-5 mb-0 pb-0">
                <AboutService2 data={aboutservice2Data1} />
            </section>
            <ServiceToken2 data={token2Data2} />
            <ServiceToken data={token2Data3} />
            <ServiceToken2 data={token2Data4} />
            <ServiceToken data={token2Data} />
            <section className="my-5 p-5">
                <h2 className="text-center">Explore the Possibilities with Extended Reality</h2>
            </section>
            <ServiceToken data={token2Data11} />
            <ServiceToken2 data={token2Data12} />
            <ServiceToken data={token2Data13} />
            <ServiceToken2 data={token2Data14} />
            <ServiceToken data={token2Data15} />
            <div className="mb-5">
                <SolutionCounter data={CounterStats2} className="my-5" showimg="false" />
            </div>
            <Create style={{ marginTop: "100px" }} data={contactData} />
            <Footer />
        </div>
    );
}

export default ExtendedReality;